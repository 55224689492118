<template>
  <section class="section background-img">
    <div class="container">
      <div class="columns is-mobile has-text-centered is-centered">
        <div class="column is-5-desktop is-5-tablet is-11-mobile is-flex is-justified-center is-aligned-items-center">
          <div class="box">
            <figure class="image is-96x96 has-image-centered has-margin-bottom-md">
              <img
                class="is-rounded"
                :src="'https://unsplash.it/256'"
              />
            </figure>

            <h3 class="title is-4 has-text-grey-dark has-text-weight-medium">Login</h3>
            <form @submit.prevent="login">
              <b-field>
                <b-input v-model="username" value="" size="is-medium" placeholder="username" icon="account mdi-dark" class="has-margin-vertical-md" required autocorrect="off" autocapitalize="none" autofocus></b-input>
              </b-field>

              <b-field>
                <b-input v-model="password" value="" size="is-medium" placeholder="password" type="password" icon="lock-open-outline mdi-dark" class="has-margin-vertical-md" required autocorrect="off" autocapitalize="none" password-reveal></b-input>
              </b-field>

              <!-- <nuxt-link to="/forgotpassword">Forgot Password?</nuxt-link> -->

              <p v-for="(error, index) in errors" :key="'error-'+index" class="help is-danger">{{error}}</p>

              <b-button native-type="submit" :loading="isLoading" :disabled="!submittable" type="is-primary" size="is-medium" class="is-fullwidth has-margin-top-md">Log in</b-button>
            </form>

            <div class="is-divider" data-content="OR"></div>

            <nuxt-link class="button is-primary is-medium is-fullwidth" :to="{ path: '/register/' }"  >
              Register
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import routerLinksMixin from "~/mixins/routerLinksMixin";

export default {
    auth: false,
    mixins: [routerLinksMixin],
    data() {
        return {
            isLoading: false,
            username: "",
            password: "",
            errors: [],
        };
    },
    computed: {
        submittable() {
            return this.username !== "" && this.password !== "";
        },
    },
    methods: {
        // async login() {
        //     this.isLoading = true;
        //     try {
        //         await this.$auth.loginWith("local", {
        //             data: {
        //                 username: this.username,
        //                 password: this.password,
        //             },
        //         });
        //         this.$route.params.postLink
        //             ? this.toUserPost(this.$route.params.postLink)
        //             : this.toHomeLink();
        //     } catch (e) {
        //         this.errors = e.response.data["non_field_errors"];
        //         console.error(e);
        //     } finally {
        //         // this.toHomeLink();
        //         this.isLoading = false;
        //     }
        // },
        async login() {
            this.isLoading = true;
            try {
                const data = await this.$axios.$post("/api/auth/login/", {
                    username: this.username,
                    password: this.password,
                });
                await this.$auth.setUserToken(data.token);
                this.$route.params.postLink
                    ? this.toUserPost(this.$route.params.postLink)
                    : this.toHomeLink();
            } catch (e) {
                this.error = e.response.data;
                console.log(this.error);
            } finally {
                // this.toHomeLink();
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
section {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
}
.has-image-centered {
    margin-left: auto;
    margin-right: auto;
}
.background-img {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("https://unsplash.it/1200/") no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    overflow: hidden;
}
.image img {
    border: 2px solid hsl(0, 0%, 0%);
    border-radius: 500px;
}
.container,
.columns {
    height: inherit;
}
.help {
    width: 40vh;
}
/* https://github.com/buefy/buefy/issues/1117#issuecomment-446407223 */
::v-deep .control.has-icons-left .icon,
.control.has-icons-right .icon {
    pointer-events: auto !important;
}
</style>
