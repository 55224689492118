export default {
    methods: {
        toLoginLink() {
            this.$router.push({
                name: "login",
            });
        },
        toLoginLinkFromPost(link) {
            this.$router.push({
                name: "login",
                params: {
                    postLink: link,
                },
            });
        },
        toRegisterLinkFromPost(link) {
            this.$router.push({
                name: "register",
                params: {
                    postLink: link,
                },
            });
        },
        toHomeLink() {
            this.$router.push({
                name: "index",
            });
        },
        toCommunityLink(community) {
            this.$router.push({
                name: "communities",
                params: {
                    id: community.id,
                },
            });
        },
        toCommunityIdLink(id) {
            this.$router.push({
                name: "communities-id",
                params: { id },
            });
        },
        toCommunities() {
            this.$router.push({
                name: "communities",
            });
        },
        toCommunityId() {
            this.$router.push({
                name: "communities-id",
            });
        },
        toUserLink(user, query = {}) {
            this.$router.push({
                name: "user-username",
                params: {
                    username: user.username,
                },
                query,
            });
        },

        toUserPost(link) {
            this.$router.push(link);
        },
        toUserPostLink(post, query = {}) {
            this.$router.push({
                name: "user-username-posts-id",
                params: {
                    id: post.id,
                    username: post.created_by.username,
                },
                query,
            });
        },
    },
};
